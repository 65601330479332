import React, { useState } from "react";
import { FaEllipsisV, FaSearch } from "react-icons/fa";
import { PDF_VIEW, PLUS_DARK } from "../../../assets";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getSelectedCase,
  joinedAs,
  party_type,
  setJoinAs,
  setParty,
} from "../../../states/slices/partyInfo.slice";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Modal from "../../../components/UI/modal";
import Button from "../../../components/button";
import moment from "moment";

function ViewFolder() {
  const joinedState = useAppSelector(joinedAs);
  const selectedParty = useAppSelector(party_type);
  const [joinAsModal, setJoinAsModal] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getCaseFolder = useAppSelector(getSelectedCase);

  // console.log(getCaseFolder);

  return (
    <main className="px-7 py-10">
      <div className="flex items-center justify-between">
        <span className="text-[22px] font-bold text-[#101828]">
          {getCaseFolder?.title}
        </span>

        {getCaseFolder?.case_status?.status === "JOIN REQUEST" ||
        getCaseFolder?.case_status?.status === "APPROVED JOIN REQUEST" ? (
          <Button
            icons={PLUS_DARK}
            iconClassName="h-[0.9rem] w-3"
            title="File new document"
            bgColor={""}
            onClick={() => {}}
            hoverColor={""}
            textColor={"text-black"}
            className={"py-3 w-fit border border-gray-200"}
          />
        ) : (
          <>
            {joinedState === "defendant" ? (
              <button
                className="bg-[#272755] text-sm font-semibold w-[211px] text-white rounded  py-2 px-4"
                onClick={() =>
                  navigate(
                    `/lawyer/file-defence-process/case-folder?id=${getCaseFolder?.id}`
                  )
                }
              >
                File Defence Processes
              </button>
            ) : (
              <button
                className="bg-[#272755] text-sm font-semibold w-[211px] text-white rounded  py-2 px-4"
                onClick={() => setJoinAsModal(true)}
              >
                Join case
              </button>
            )}
          </>
        )}
      </div>
      <div className="w-[264px] h-[40px] rounded-md flex items-center mt-6 justify-between border px-2">
        <input type="text" className="outline-none w-full bg-transparent" />
        <FaSearch size={16} color="#000000" />
      </div>
      <div className="flex items-center gap-x-5 mt-6">
        {getCaseFolder?.case_documents.map((item: any) => (
          <div className="w-[353.02px] relative h-[353.02px] rounded-lg bg-[#ECEEF5] border-2 border-[#E3E6E9] flex items-center justify-center">
            <img
              src={PDF_VIEW}
              className="w-[294px] h-[294px] rotate-1"
              alt=""
            />
            <div className="w-full h-[100.02px] flex items-center justify-between bg-white absolute bottom-0 px-5">
              <div className="flex flex-col items-start gap-y-2">
                <span className="text-[20px] font-medium ">{item?.title}</span>
                <span className="text-[#848484] font-medium text-xs">
                  {/* May 2, 2023 8:45pm */}
                  {moment(item?.created_at).format("MMM D, YYYY h:mma")}
                </span>
              </div>
              <FaEllipsisV className="cursor-pointer" />
            </div>
          </div>
        ))}
      </div>
      <Modal maxWidth={"xs"} open={joinAsModal} close={() => setJoinAs(false)}>
        <div className="px-10 flex flex-col gap-y-5">
          <span className="font-semibold text-2xl text-black">
            Select who you are joining as
          </span>

          <div className="flex items-start flex-col gap-y-2">
            <div
              className="flex items-center gap-x-2 "
              onClick={() => dispatch(setParty("DEFENDANT"))}
            >
              <div className="w-[18px] cursor-pointer h-[18px] border border-[#272755] rounded-full flex items-center justify-center">
                {selectedParty === "DEFENDANT" && (
                  <div className="w-[10px] h-[10px] rounded-full bg-[#272755]"></div>
                )}
              </div>
              <span className="text-lg fontsemibold">Defendant Counsel</span>
            </div>
            <div
              className="flex items-center gap-x-2"
              onClick={() => dispatch(setParty("CLAIMANT"))}
            >
              <div className="w-[18px] h-[18px] cursor-pointer border border-[#272755] rounded-full flex items-center justify-center">
                {selectedParty === "CLAIMANT" && (
                  <div className="w-[10px] h-[10px] rounded-full bg-[#272755]"></div>
                )}
              </div>
              <span className="text-lg fontsemibold">Plaintiff Counsel</span>
            </div>
          </div>

          <button
            className={`w-full ${
              joinedState === "" && "opacity-50 cursor-not-allowed"
            } py-3 mb-5 rounded font-semibold text-white bg-[#272755] text-[17.8px]`}
            onClick={() => {
              navigate("/lawyer/join-as-plaintiff");
              setJoinAsModal(false);
            }}
          >
            Proceed
          </button>
        </div>
      </Modal>
    </main>
  );
}

export default ViewFolder;
