import React, { useEffect, useRef, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import Api from "../../../config";
import { ADD_BUTTON, PDF, SUCCESS_IMAGE, UPLOAD_ICON } from "../../../assets";
import { object } from "yup";
import toast from "react-hot-toast";
import { useAppSelector } from "../../../hooks";
import {
  getSelectedCase,
  party_type,
  suit_number,
} from "../../../states/slices/partyInfo.slice";
import { getFormDataHeader } from "../../../utils";
import Modal from "../../../components/UI/modal";

const StepSignal = ({
  step,
  currentStep,
  title,
}: {
  step: number;
  currentStep: number;
  title: string;
}) => (
  <div className="flex flex-col items-center gap-y-1">
    <div
      className={`w-[100px] rounded-3xl h-[12px] ${
        currentStep >= step ? "  bg-[#BDBEE0]" : " bg-[#E5E5E5]"
      } `}
    ></div>
    <span
      className={` ${
        currentStep >= step ? "text-black" : "text-[#9A9A9A]"
      } text-xs font-normal `}
    >
      {title}{" "}
    </span>
  </div>
);

type CaseDocType = {
  title: string;
  file: File | null;
};
function Plaintiff() {
  const [currentStep, setCurrentStep] = useState(1);
  const [parties, setParties] = useState([
    {
      first_name: "",
      last_name: "",
      email: "",
      law_firm: "",
      address: "",
      partyType: "",
      phone_number: "",
      whatsapp_number: "",
    },
  ]);
  const [caseDocument, setCaseDocument] = useState<CaseDocType[]>([
    {
      title: "",
      file: null,
    },
  ]);
  const navigate = useNavigate();
  const fileInputRef = useRef<any>([]);
  const getSuitNumber = useAppSelector(suit_number);
  const selectedPartyType = useAppSelector(party_type);
  const [formdata, setFormData] = useState({
    case_document_title: "",
    counsel_detail_first_name: "",
    counsel_detail_whatsapp_number: "",
    counsel_detail_phone_number: "",
    counsel_detail_email: "",
    counsel_detail_address: "",
    counsel_detail_firm_name: "",
    counsel_detail_last_name: "",
  });
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    const file = e.target.files && e.target.files[0];

    const updatedCaseDocs = caseDocument.map((doc, i) =>
      i === id ? { ...doc, file } : doc
    );

    setCaseDocument(updatedCaseDocs);
  };

  const handleCaseInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const updatedCaseDoc = caseDocument.map((doc, i) =>
      i === index ? { ...doc, [name]: value } : doc
    );

    setCaseDocument(updatedCaseDoc);
  };
  const handlePartyInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const updatedParty = parties.map((party, i) =>
      i === index ? { ...party, [name]: value } : party
    );

    setParties(updatedParty);
  };

  const handleAddCaseField = () => {
    setCaseDocument([
      ...caseDocument,
      {
        title: "",
        file: null,
      },
    ]);
  };
  const handleAddPartyField = () => {
    setParties([
      ...parties,
      {
        first_name: "",
        last_name: "",
        email: "",
        law_firm: "",
        partyType: "",
        address: "",
        phone_number: "",
        whatsapp_number: "",
      },
    ]);
  };
  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };
  const handleRemoveCaseField = (index: number) => {
    const updatedCaseDoc = caseDocument.map((doc, i) =>
      i === index ? { ...doc, file: null } : doc
    );

    setCaseDocument(updatedCaseDoc);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleJoinCase = async () => {
    setLoading(true);

    const payload = {
      case_documents: caseDocument.map((doc, index) => ({
        title: doc.title,
        file_path: doc.file,
      })),
      suit_number: getSuitNumber,
      parties: parties.map((p, index) => ({
        first_name: p.first_name,
        last_name: p.last_name,
        phone_number: p.phone_number,
        email: p.email,
        address: p.address,
        party: p.partyType,
      })),
      party: selectedPartyType,
      counsel_detail_first_name: formdata.counsel_detail_first_name,
      counsel_detail_last_name: formdata.counsel_detail_last_name,
      counsel_detail_firm_name: formdata.counsel_detail_firm_name,
      counsel_detail_email: formdata.counsel_detail_email,
      counsel_detail_phone_number: formdata.counsel_detail_phone_number,
      counsel_detail_whatsapp_number: formdata.counsel_detail_whatsapp_number,
      counsel_detail_address: formdata.counsel_detail_address,
    };
    try {
      const response = await Api.post(
        "/lawyer/case-folders/join-as-third-party-counsel",
        payload,
        getFormDataHeader()
      );
      if (response) {
        toast.success(response?.data?.message);
        setLoading(false);
        setSuccessModal(true);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      fileInputRef.current = [];
    };
  }, []);

  return (
    <main className="flex items-center justify-center flex-col mt-20">
      <span className="text-[28px] font-semibold text-black">
        Provide details of counsel
      </span>
      <div className="flex items-center gap-x-5 mt-5">
        <StepSignal
          step={1}
          currentStep={currentStep}
          title="Party information"
        />
        <StepSignal
          step={2}
          currentStep={currentStep}
          title="Details of counsel"
        />
        <StepSignal
          step={3}
          currentStep={currentStep}
          title="Upload documents"
        />
      </div>

      {currentStep === 1 && (
        <div className="w-[575px] h-full shadow-lg bg-white px-16 py-10 mt-6">
          <span className="text-[20px] font-semibold text-black">
            Details of party
          </span>
          {parties.map((party, index) => (
            <div className="mt-6 flex flex-col">
              {/* <span className="text-[16px] font-semibold">
                Party {index + 1}
              </span> */}
              <select
                name="partyType"
                id=""
                className="text-[16px] font-semibold outline-none w-full border-none bg-transparent"
                value={party.partyType}
                onChange={(e: any) => handlePartyInputChange(e, index)}
              >
                <option value="">Select party</option>
                <option value="DEFENDANT">Defendant</option>
                <option value="CLAIMANT">Claimant</option>
              </select>

              <form
                action=""
                className="flex items-start flex-col gap-y-5 py-3"
              >
                <input
                  type="text"
                  name="first_name"
                  className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                  placeholder="First name"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handlePartyInputChange(e, index)
                  }
                  value={party.first_name}
                />
                <input
                  type="text"
                  name="last_name"
                  className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                  placeholder="Last name"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handlePartyInputChange(e, index)
                  }
                  value={party.last_name}
                />
                <input
                  type="text"
                  name="law_firm"
                  className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                  placeholder="Name of  law firm"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handlePartyInputChange(e, index)
                  }
                  value={party.law_firm}
                />
                <input
                  type="email"
                  name="email"
                  className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                  placeholder="Email address"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handlePartyInputChange(e, index)
                  }
                  value={party.email}
                />
                <input
                  type="text"
                  name="address"
                  className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                  placeholder="Address for service"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handlePartyInputChange(e, index)
                  }
                  value={party.address}
                />
                <input
                  type="text"
                  name="phone_number"
                  className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                  placeholder="Phone number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handlePartyInputChange(e, index)
                  }
                  value={party.phone_number}
                />
                <input
                  type="text"
                  name="whatsapp_number"
                  className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                  placeholder="Whatsapp number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handlePartyInputChange(e, index)
                  }
                  value={party.whatsapp_number}
                />
              </form>
              <button
                className="w-full flex items-end justify-end text-sm rounded py-3 mt-9 text-[#272755] font-semibold"
                onClick={handleAddPartyField}
              >
                + Add more fields
              </button>
            </div>
          ))}
          <button
            className="w-full bg-[#272755] text-[17.8px] rounded py-3 mt-9 text-white font-semibold"
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      )}

      {currentStep === 2 && (
        <div className="w-[575px] h-full shadow-lg bg-white px-16 py-10 mt-6">
          <span className="text-[20px] font-semibold text-black">
            Details of party
          </span>

          <div className="mt-6">
            <span className="text-[16px] font-semibold">Plaintiff Counsel</span>

            <form action="" className="flex items-start flex-col gap-y-5 py-3">
              <input
                type="text"
                name="counsel_detail_first_name"
                className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                placeholder="First name"
                onChange={handleInputChange}
                value={formdata.counsel_detail_first_name}
              />
              <input
                type="text"
                name="counsel_detail_last_name"
                className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                placeholder="First name"
                onChange={handleInputChange}
                value={formdata.counsel_detail_last_name}
              />
              <input
                type="text"
                name="counsel_detail_firm_name"
                className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                placeholder="Name of  law firm"
                onChange={handleInputChange}
                value={formdata.counsel_detail_firm_name}
              />
              <input
                type="email"
                name="counsel_detail_email"
                className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                placeholder="Email address"
                onChange={handleInputChange}
                value={formdata.counsel_detail_email}
              />
              <input
                type="text"
                name="counsel_detail_address"
                className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                placeholder="Address for service"
                onChange={handleInputChange}
                value={formdata.counsel_detail_address}
              />
              <input
                type="text"
                name="counsel_detail_phone_number"
                className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                placeholder="Phone number"
                onChange={handleInputChange}
                value={formdata.counsel_detail_phone_number}
              />
              <input
                type="text"
                name="counsel_detail_whatsapp_number"
                className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                placeholder="Whatsapp number"
                onChange={handleInputChange}
                value={formdata.counsel_detail_whatsapp_number}
              />
            </form>
          </div>
          <button
            className="w-full bg-[#272755] text-[17.8px] rounded py-3 mt-9 text-white font-semibold"
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      )}

      {currentStep === 3 && (
        <div className="w-[575px] h-full shadow-lg bg-white p-10 mt-6">
          <span className="text-[20px] font-semibold text-black">
            Upload case documents
          </span>
          {caseDocument.map((docs, index) => (
            <>
              <div className="mt-2 flex-col flex gap-y-2" key={index}>
                <span className="text-[16px] font-semibold">Case title</span>
                <input
                  type="text"
                  name="title"
                  className="w-full border border-[#CACACA] rounded outline-none bg-transparent placeholder:text-sm placeholder:text-black py-4 px-3"
                  placeholder="Enter case title"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleCaseInputChange(e, index)
                  }
                  value={docs.title}
                />
              </div>

              <div className="mt-6 flex flex-col gap-y-2 items-end justify-end">
                <span className="text-[16px] font-semibold flex w-full items-start">
                  Upload documents
                </span>
                {docs.file ? (
                  <div className="w-full h-full border border-[#C7C7CC] p-10 rounded-lg">
                    <div className="flex items-start gap-x-3">
                      <img src={PDF} className="w-[28px] h-[28px]" alt="" />
                      <div>
                        <div className="flex items-start justify-between">
                          <div className="flex items-start gap-x-3">
                            <div className="flex flex-col gap-y-1">
                              <span className="text-sm font-medium text-[#232A35]">
                                {docs.file.name}
                              </span>
                              <span className="text-[#667085] font-normal text-sm">
                                PDF (max .5 MB)
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center h-[16px] w-[16px] justify-center rounded-full bg-[#272755] ">
                            <FaCheck size={10} color="white" />
                          </div>
                        </div>
                        <div className="flex items-center gap-x-3">
                          <div className="w-[268px] h-[8px] bg-[#272755] rounded-lg"></div>
                          <span>100 %</span>
                        </div>
                        <div className="flex items-center gap-x-2">
                          <button className="text-sm font-semibold text-[#6C757D]">
                            View
                          </button>
                          <button
                            className="text-sm font-semibold text-[#6C757D]"
                            onClick={() => handleRemoveCaseField(index)}
                          >
                            Delete
                          </button>
                          <button
                            className="text-sm font-semibold text-[#6C757D]"
                            onClick={() => fileInputRef.current[index].click()}
                          >
                            Upload another
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="w-full h-[126px] cursor-pointer  mt-3 border border-[#EAECF0] flex items-center justify-center flex-col rounded-md"
                    onClick={() =>
                      // @ts-ignore
                      fileInputRef.current[index].click()
                    }
                  >
                    <img
                      src={UPLOAD_ICON}
                      className="w-[40px] h-[40px]"
                      alt=""
                    />

                    <span className="text-[14px] font-semibold">
                      Click to upload{" "}
                      <span className="text-[#667085]">
                        Example receipt.pdf
                      </span>
                    </span>

                    <span className="text-[#667085] text-sm">
                      PDF (max 5mb)
                    </span>
                  </div>
                )}

                <input
                  type="file"
                  accept=".pdf"
                  className="file_upload sr-only"
                  ref={(ref: any) => (fileInputRef.current[index] = ref)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleFileChange(e, index)
                  }
                />
                <button
                  type="button"
                  onClick={handleAddCaseField}
                  className="flex justify-end items-end"
                >
                  <img src={ADD_BUTTON} className="w-[20px] h-[20px] " alt="" />
                </button>
              </div>
            </>
          ))}
          <button
            className={`w-full ${
              loading ? "bg-opacity-50 cursor-wait" : "bg-opacity-100"
            } bg-[#272755] text-[17.8px] rounded py-3 mt-9 text-white font-semibold`}
            onClick={handleJoinCase}
          >
            {loading ? "please wait..." : "  Submit"}
          </button>
        </div>
      )}

      <Modal
        open={successModal}
        close={() => setSuccessModal(false)}
        maxWidth={"xs"}
      >
        <div className="flex items-center justify-center flex-col px-5 py-5">
          <img src={SUCCESS_IMAGE} className="w-[211px] h-[171px]" alt="" />
          <span className="text-[20px] font-semibold">
            Submitted successfully
          </span>

          <button
            className={`w-full bg-[#272755] text-[17.8px] rounded py-3 mt-9 text-white font-semibold`}
            onClick={() => {
              setSuccessModal(false);
              navigate("/lawyer/my-files");
            }}
          >
            Close
          </button>
        </div>
      </Modal>
    </main>
  );
}

export default Plaintiff;
