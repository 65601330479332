import {
  Dashboard,
  MyFiles,
  Notification,
  Payment,
  Profile,
} from "../features/lawyer";
import CreateCase from "../features/lawyer/createcase";
import EditCases from "../features/lawyer/edit-cases";
import FileDefence from "../features/lawyer/file-defence";
import Plaintiff from "../features/lawyer/plaintiff";
import ProceedPayment from "../features/lawyer/proceed-payment";
import SearchResult from "../features/lawyer/search-result";
import ViewCases from "../features/lawyer/view-cases";
import ViewFolder from "../features/lawyer/view-folder";

export const lawyerRoutes = [
  {
    id: "1",
    title: "Dashboard",
    component: Dashboard,
    path: "lawyer",
  },
  {
    id: "2",
    title: "My Files",
    component: MyFiles,
    path: "lawyer/my-files",
  },
  {
    id: "3",
    title: "Notification",
    component: Notification,
    path: "lawyer/notification",
  },
  {
    id: "4",
    title: "Profile",
    component: Profile,
    path: "lawyer/profile",
  },
  {
    id: "5",
    title: "search",
    component: SearchResult,
    path: "lawyer/search-result",
  },
  {
    id: "6",
    title: "folder",
    component: ViewFolder,
    path: "lawyer/view-folder",
  },
  {
    id: "7",
    title: "ProceedPayment",
    component: ProceedPayment,
    path: "lawyer/proceed",
  },
  {
    id: "8",
    title: "ViewCases",
    component: ViewCases,
    path: "lawyer/view-case",
  },
  {
    id: "9",
    title: "EditCases",
    component: EditCases,
    path: "lawyer/edit-case",
  },
  {
    id: "9",
    title: "filedefence",
    component: FileDefence,
    path: "lawyer/file-defence-process/case-folder",
  },
  {
    id: "9",
    title: "filedefence",
    component: Plaintiff,
    path: "lawyer/join-as-plaintiff",
  },
  {
    id: "9",
    title: "createcase",
    component: CreateCase,
    path: "lawyer/create-case",
  },
];
