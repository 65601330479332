import React, { useEffect, useState } from "react";
import Typography from "../../../components/Typography";
import {
  MdOutlineDriveFolderUpload,
  MdOutlineSmsFailed,
  MdPendingActions,
} from "react-icons/md";
import { TiTick } from "react-icons/ti";
import Button from "../../../components/button";
import { FOLDER, PLUS, PLUS_DARK } from "../../../assets";
import {
  CASE_COLUMNS,
  SEARCH_RESULT_COLUMN,
} from "../../../components/table/columns";
import { selectUser } from "../../../states/slices/authReducer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import CreateCases from "../components/create-cases";
import Api from "../../../config";
import PaginatedTable from "../../../components/table/Paginated-table";
import { getJSONheader } from "../../../utils";
import Modal from "../../../components/UI/modal";
import {
  joinedAs,
  setJoinAs,
  suit_number,
} from "../../../states/slices/partyInfo.slice";
import { FaSearch } from "react-icons/fa";
import { TableComponent } from "../../../components/table/Table";
// import Skeleton from "react-loading-skeleton";
import { Box, Skeleton } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { spawn } from "child_process";

function SearchResult() {
  const [active, setActive] = useState(0);
  const [searchResult, setSearchResult] = useState<any>([]);
  const user = useAppSelector(selectUser);
  const [suitmodal, setSuitModal] = useState(false);
  const [suit_no, setSuitNo] = useState("");
  const [open, setOpen] = useState(false);
  const [joincasemodal, setJoinCaseModal] = useState(false);
  const [data, setData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const suit_params = searchParams.get("suit_number") || "";
  // const suitNumber = useAppSelector(suit_number);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(3);
  const fetchData = async (page: any, pageSize: any) => {
    setLoading(true);
    try {
      const response = await Api.get(
        `/lawyer/case-folders?page=${page + 1}`,
        getJSONheader()
      );
      const { data, meta } = response.data;
      setLoading(false);
      setData(data);
      setTotalPages(meta.last_page);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataBySuitNo = async () => {
    setLoading(true);
    try {
      const response = await Api.get(
        `/lawyer/case-folders/suit-number?suit_number=${suit_params}`,
        getJSONheader()
      );

      const { data } = response?.data;
      const results = [data]; // converts an object to an array
      setData(results);
      setSearchResult(results);
      console.log(data);

      setLoading(false);
    } catch (error) {
      console.error("error fetching data", error);
    }
  };

  useEffect(() => {
    fetchDataBySuitNo();
  }, []);

  return (
    <div className="pt-4 px-2 lg:px-8 pb-36 lg:pb-0">
      <Typography textStyle="text-[22px] font-bold">Search Result</Typography>
      <div className="w-full  pb-20 lg:pb-8">
        <div className="w-[264px] h-[40px] rounded-md flex items-center mt-3 justify-between border px-2">
          <input type="text" className="outline-none w-full bg-transparent" />
          <FaSearch size={16} color="#000000" />
        </div>

        <div className="mt-8 lg:flex justify-between items-end">
          <div className=" w-full lg:mb-0">
            <div className=" border-b  border-[#F0F0F0] rounded-b-xl">
              {searchResult.length > 0 ? (
                // <TableComponent
                //   className="rounded-none rounded-t-0"
                //   DATA={searchResult}
                //   COLUMNS={SEARCH_RESULT_COLUMN}
                //   TITLE=""
                //   isExport={null}
                //   isPaginated={null}
                //   isSearch={null}
                //   size={size}
                //   setSize={setSize}
                //   setPageIndex={(value: number) => page + 1}
                // />
                <PaginatedTable
                  columns={SEARCH_RESULT_COLUMN}
                  data={data}
                  totalPages={totalPages}
                  fetchData={fetchDataBySuitNo}
                  isLoading={isLoading}
                />
              ) : isLoading === false && searchResult.length < 1 ? (
                <div className="flex flex-col justify-center items-center h-52">
                  <img src={FOLDER} className="h-20" />
                  <p className="text-center text-xs">
                    No Case Folder available at the moment
                  </p>
                </div>
              ) : isLoading ? (
                <Box sx={{ pt: 0.5, pb: 2 }}>
                  <Skeleton width="60%" />
                  <Skeleton />
                  <Skeleton variant="rectangular" width={"100%"} height={100} />
                </Box>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <CreateCases {...{ open, setOpen }} />
    </div>
  );
}

export default SearchResult;
