import React, { useEffect, useMemo } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import "./table.css";
import { GlobalFilter } from "./filter/GlobalFilter";
import Button from "../button";
import { FILTER } from "../../assets";
import { Zoom } from "react-reveal";

export const TableComponent = (props: any) => {
  const {
    COLUMNS,
    DATA,
    TITLE,
    setSize,
    size,

    isSearch,
    setPageIndex,
    className,
    isExport,
    isPaginated,
  } = props;
  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const data = useMemo(() => DATA, [DATA]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    // pageCount,
    prepareRow,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  useEffect(() => {
    setSize(pageSize);
    setPageIndex(pageIndex);
  }, [pageIndex, pageSize, setSize, setPageIndex]);

  return (
    <>
      <Zoom>
        <div className="flex justify-between items-center  w-full border-b border-[#F0F0F0] pt-2 px-4">
          {isSearch && (
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          )}

          <div className="flex justify-start lg:justify-end items-center lg:items-start mb-1">
            {isExport && (
              <Button
                title="Export"
                className="border border-black rounded-lg ml-4"
              />
            )}
          </div>
        </div>
        <div className="w-full overflow-scroll bg-transparent">
          <div
            className={`w-full overflow-scroll text-sm lg:rounded-t-3xl ${className}`}
          >
            <p className="font-semibold text-sm  px-4 ">{TITLE}</p>
            {/* <hr /> */}

            <table className="w-full table  px-8" {...getTableProps()}>
              <thead className="w-full ">
                {headerGroups.map((headerGroup: any) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className=" whitespace-nowrap text-left pl-6 pr-3 pt-3 text-sm font-semibold"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="px-8">
                {page.map((row: any) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell: any) => {
                        return (
                          <td
                            className="pl-6 pr-3 py-4 border-0  text-sm"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {isPaginated && (
            <div className="flex justify-end items-center w-full px-2 lg:px-4 py-2">
              <div className="flex justify-center items-center text-sm border rounded-sm py-1 border-[#F0F0F0] w-full lg:w-[50%]">
                <button
                  className="pl-4"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </button>

                <span className="pl-4 text-sm">
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
                <span className="pl-4">
                  | Go to page:{" "}
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={(e) => {
                      const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(pageNumber);
                    }}
                    className="bg-transparent py-0 text-sm lg:rounded-l-md outline-none border border-gray-100 pl-2"
                    style={{ width: "50px" }}
                  />
                </span>
                <button
                  className=""
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  {">"}
                </button>
                <select
                  className=" bg-transparent py-0 text-sm lg:rounded-r-md outline-0 focus:border-0 focus:border-black"
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {[5, 15, 30].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      </Zoom>
    </>
  );
};
