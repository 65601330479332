import { useState } from "react";
import Typography from "../../../components/Typography";
import {
  MdOutlineDriveFolderUpload,
  MdOutlineSmsFailed,
  MdPendingActions,
} from "react-icons/md";
import { TiTick } from "react-icons/ti";
import Button from "../../../components/button";
import { PLUS } from "../../../assets";
import {
  CASE_COLUMNS,
  CASE_REGISTRA_COLUMNS,
} from "../../../components/table/columns";
import { selectUser } from "../../../states/slices/authReducer";
import { useAppSelector } from "../../../hooks";
import Api from "../../../config";
import PaginatedTable from "../../../components/table/Paginated-table";
import { getJSONheader } from "../../../utils";

function MyFiles() {
  const [active, setActive] = useState(0);
  const user = useAppSelector(selectUser);
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState<string>("");
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const fetchData = async (page: any, pageSize: any) => {
    setLoading(true);
    try {
      const response = await Api.get(
        `/registra1/case-folders?page=${page + 1}`,
        getJSONheader()
      );
      const { data, meta } = response.data;
      setLoading(false);
      setData(data);
      setTotalPages(meta.last_page);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="pt-4 px-8 pb-36 lg:pb-0">
      <Typography textStyle="text-[20px] font-medium">My Files</Typography>
      <div className="w-full  pb-20 lg:pb-0">
        <div className="w-full flex flex-row justify-between items-start pl-5 pt-4 lg:pt-0">
          <div className="flex justify-between items-center w-[70%]">
            <div
              onClick={() => setActive(0)}
              className={` flex  w-full items-center py-2 px-5 cursor-pointer ${
                active === 0 ? " text-brand_blue font-medium" : ""
              }`}
            >
              <MdOutlineDriveFolderUpload className="h-5  mr-4" />
              <Typography variant="label">All files (10)</Typography>
            </div>
            <div
              onClick={() => {
                setActive(1);
                setParams("PENDING");
              }}
              className={` flex  w-full items-center py-2 px-5 cursor-pointer  ${
                active === 1 ? " text-brand_blue font-medium" : ""
              }`}
            >
              <TiTick className="h-5  mr-4" />
              <Typography variant="label">Pending (10)</Typography>
            </div>
            <div
              onClick={() => {
                setActive(2);
                setParams("IN COURT");
              }}
              className={` flex  w-full items-center py-2 px-5 cursor-pointer ${
                active === 2 ? " text-brand_blue font-medium" : ""
              }`}
            >
              <TiTick className="h-5  mr-4" />
              <Typography variant="label">In Court (10)</Typography>
            </div>
          </div>
        </div>

        <div className="mt-8 lg:flex justify-between items-end">
          <div className=" w-full lg:mb-0">
            <div className=" border-b  border-[#F0F0F0] rounded-b-xl">
              <PaginatedTable
                columns={CASE_REGISTRA_COLUMNS}
                data={data}
                totalPages={totalPages}
                fetchData={fetchData}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyFiles;
