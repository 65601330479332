import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectUser } from "../../../states/slices/authReducer";
import { Link, useNavigate } from "react-router-dom";
import {
  getSelectedCase,
  removeValue,
  setReload,
  setSingleParty,
  setToggle,
  setToggle_2,
  updateCase,
} from "../../../states/slices/partyInfo.slice";
import Api from "../../../config";
import { getJSONheader } from "../../../utils";
import { toast } from "react-hot-toast";
import { FaEllipsisV, FaEye, FaUser } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Transition } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";

export const TRANSACTION_COLUMNS = [
  {
    Header: "Sys No.",
    accessor: "id",
  },
  {
    Header: "Suit no",
    accessor: "suit_number",
  },
  {
    Header: "Case Title",
    accessor: "title",
  },

  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Date created",
    accessor: "created_at",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
export const CASE_COLUMNS = [
  {
    Header: "S/No.",

    Cell: ({ cell: { row } }: any) => {
      return <span className="text-xs">{Number(row.id) + 1}</span>;
    },
  },
  {
    Header: "Suit no",
    accessor: "suit_number",
  },
  {
    Header: "Case Title",
    accessor: "title",
  },
  {
    Header: "Date created",
    accessor: "created_at",
    Cell: ({ cell: { row } }: any) => {
      return moment(row.values.created_at).format("MMM Do YYYY");
    },
  },
  {
    Header: "Status",
    // accessor: '',
    Cell: ({ cell: { row } }: any) => {
      return (
        <button
          className={`px-2 py-1 text-gray-500 text-xs cursor-default  rounded-md capitalize ${
            row.original?.case_status?.status === "PENDING"
              ? "bg-yellow-200"
              : row.original?.case_status?.status === "MOTION"
              ? "bg-yellow-200"
              : row.original?.case_status?.status === "IN COURT"
              ? "bg-[#F2994A]"
              : row.original?.case_status?.status === "NOT SERVED"
              ? "bg-red-100"
              : row.original?.case_status?.status === "REJECTED"
              ? "bg-red-100"
              : row.original?.case_status?.status === "FRESH CASE"
              ? "bg-green-200"
              : row.original?.case_status?.status === "ASSIGN"
              ? "bg-orange-200"
              : "bg-green-200"
          }`}
        >
          {row.original?.case_status?.status}
        </button>
      );
    },
  },
  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      // console.log(row, 'ROW');
      const dispatch = useAppDispatch();

      return (
        <>
          {row.original?.case_status?.status === "ACCEPTED" ? (
            <Link to={`/lawyer/proceed?id=${row.original?.id}`}>
              <button
                onClick={() => dispatch(updateCase(row.original))}
                className="px-1 py-1 bg-brand_indigo text-[#fff] text-xs  w-20  rounded-md"
              >
                Proceed
              </button>
            </Link>
          ) : row.original?.case_status?.status === "REJECTED" ? (
            <Link to={`/lawyer/edit-case?id=${row.original?.id}`}>
              <button
                onClick={() => dispatch(updateCase(row.original))}
                className="px-1 py-1 bg-yellow-300 text-[#fff] text-xs  w-20  rounded-md"
              >
                Edit
              </button>
            </Link>
          ) : (
            <Link to={`/lawyer/view-case?id=${row.original?.id}`}>
              <button
                onClick={() => dispatch(updateCase(row.original))}
                className="px-1 py-1 bg-brand_blue text-[#fff] text-xs  w-20  rounded-md"
              >
                View
              </button>
            </Link>
          )}
        </>
      );
    },
  },
];
export const SEARCH_RESULT_COLUMN = [
  {
    Header: "S/No.",

    Cell: ({ cell: { row } }: any) => {
      return <span className="text-xs">{Number(row.id) + 1}</span>;
    },
  },
  {
    Header: "Suit no",
    accessor: "suit_number",
    Cell: ({ row }: { row: any }) => <span>{row?.original?.suit_number}</span>,
  },
  {
    Header: "Case Title",
    accessor: "title",
  },
  // {
  //   Header: "Status",
  //   // accessor: '',
  //   Cell: ({ cell: { row } }: any) => {
  //     return (
  //       <button
  //         className={`px-2 py-1 text-gray-500 text-xs cursor-default  rounded-md capitalize ${
  //           row.original?.case_status?.status === "PENDING"
  //             ? "bg-yellow-200"
  //             : row.original?.case_status?.status === "MOTION"
  //             ? "bg-yellow-200"
  //             : row.original?.case_status?.status === "IN COURT"
  //             ? "bg-[#F2994A]"
  //             : row.original?.case_status?.status === "NOT SERVED"
  //             ? "bg-red-100"
  //             : row.original?.case_status?.status === "REJECTED"
  //             ? "bg-red-100"
  //             : row.original?.case_status?.status === "FRESH CASE"
  //             ? "bg-green-200"
  //             : row.original?.case_status?.status === "ASSIGN"
  //             ? "bg-orange-200"
  //             : "bg-green-200"
  //         }`}
  //       >
  //         {row.original?.case_status?.status}
  //       </button>
  //     );
  //   },
  // },
  {
    Header: "Details",
    accessor: "",
    Cell: () => (
      <div className="flex items-center gap-x-1 cursor-pointer">
        <span>View</span>
        <FaEye size={15} color="#667085" />
      </div>
    ),
  },
  {
    Header: "Query status",
    accessor: "query_status",
  },
  {
    Header: "Payment",
    accessor: "payment",
    Cell: ({ row }: { row: any }) => {
      return (
        row?.original?.payment_status === "success" && (
          <div className="w-fit h-full px-2 py-1 text-white text-xs font-medium bg-green-500 rounded-xl">
            Sucessful
          </div>
        )
      );
    },
  },
  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      // console.log(row, 'ROW');
      const dispatch = useAppDispatch();
      const navigate = useNavigate();
      const [dropdown, setDropdown] = useState(false);

      return (
        <>
          {/* {row.original?.case_status?.status === "ACCEPTED" ? (
            <Link to={`/lawyer/proceed?id=${row.original?.id}`}>
              <button
                onClick={() => dispatch(updateCase(row.original))}
                className="px-1 py-1 bg-brand_indigo text-[#fff] text-xs  w-20  rounded-md"
              >
                Proceed
              </button>
            </Link>
          ) : row.original?.case_status?.status === "REJECTED" ? (
            <Link to={`/lawyer/edit-case?id=${row.original?.id}`}>
              <button
                onClick={() => dispatch(updateCase(row.original))}
                className="px-1 py-1 bg-yellow-300 text-[#fff] text-xs  w-20  rounded-md"
              >
                Edit
              </button>
            </Link>
          ) : (
            <Link to={`/lawyer/view-case?id=${row.original?.id}`}>
              <button
                onClick={() => dispatch(updateCase(row.original))}
                className="px-1 py-1 bg-brand_blue text-[#fff] text-xs  w-20  rounded-md"
              >
                View
              </button>
            </Link>
          )} */}
          <FaEllipsisV
            size={20}
            color="#101828"
            className="hover:bg-green-100 p-1 cursor-pointer rounded-md"
            onClick={() => setDropdown((prev) => !prev)}
          />
          <Transition
            as="div"
            show={dropdown}
            className="w-[170px] h-[101px] flex flex-col gap-y-2 items-start rounded-xl shadow-lg absolute right-20 z-30 bg-white mt-6 text-sm font-medium py-5"
          >
            <span
              className="hover:bg-[#BFD0F2] w-full py-1 pl-3 cursor-pointer"
              onClick={() => {
                navigate(`/lawyer/view-folder?=${row?.original?.id}`);
                dispatch(updateCase(row?.original));
              }}
            >
              Open folder
            </span>
            <span className="hover:bg-[#BFD0F2] w-full py-1 pl-3 cursor-pointer">
              View case details
            </span>
          </Transition>
        </>
      );
    },
  },
];
export const CASE_REGISTRA_COLUMNS = [
  {
    Header: "S/No.",

    Cell: ({ cell: { row } }: any) => {
      return <span className="text-xs">{Number(row.id) + 1}</span>;
    },
  },
  {
    Header: "Case number",
    accessor: "case_number",
  },
  {
    Header: "Suit no",
    accessor: "suit_number",
  },
  {
    Header: "Case Title",
    accessor: "title",
  },
  {
    Header: "Date created",
    accessor: "created_at",
    Cell: ({ cell: { row } }: any) => {
      return moment(row.values.created_at).format("MMM Do YYYY");
    },
  },
  {
    Header: "Amount Paid (₦)",
    accessor: "amount_paid",
  },

  {
    Header: "Status",
    Cell: ({ cell: { row } }: any) => {
      return (
        <button
          className={`px-2 py-1 text-gray-500 text-xs cursor-default  rounded-md capitalize ${
            row.original?.case_status?.status === "PENDING"
              ? "bg-yellow-200"
              : row.original?.case_status?.status === "IN COURT"
              ? "bg-[#F2994A]"
              : row.original?.case_status?.status === "RECALLED"
              ? "bg-red-200"
              : "bg-green-200"
          }`}
        >
          {row.original?.case_status?.status}
        </button>
      );
    },
  },

  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      const dispatch = useAppDispatch();
      const user = useAppSelector(selectUser);

      return (
        <>
          {row.original?.case_status?.status === "RECALLED" ? (
            // <Link to={'#'}>
            <button
              onClick={() => dispatch(updateCase(row.original))}
              className="px-1  cursor-default py-1 bg-yellow-500 text-[#fff] text-xs  w-20  rounded-md"
            >
              In Progress
            </button>
          ) : (
            // </Link>
            <Link
              to={
                user.role === "REGISTRAR 2"
                  ? `/registrar-2/process?id=${row.original?.id}`
                  : user.role === "PRESIDING JUDGE"
                  ? `/presiding-judge/process?id=${row.original?.id}`
                  : user.role === "PRESIDING MAGISTRATE"
                  ? `/presiding-magistrate/process?id=${row.original?.id}`
                  : `/registrar-1/process?id=${row.original?.id}`
              }
            >
              <button
                onClick={() => dispatch(updateCase(row.original))}
                className="px-1 py-1 bg-brand_indigo text-[#fff] text-xs  w-20  rounded-md"
              >
                Process
              </button>
            </Link>
          )}
        </>
      );
    },
  },
];
export const JUDGES_COLUMNS = [
  {
    Header: "S/No.",

    Cell: ({ cell: { row } }: any) => {
      return <span className="text-xs">{Number(row.id) + 1}</span>;
    },
  },
  {
    Header: "Judges",

    Cell: ({ cell: { row } }: any) => {
      return (
        <div className="flex justify-start items-center">
          <FaUser className="text-brand_blue" />
          <p className="text-sm ml-3">
            {row.original?.first_name} {row.original?.last_name}
          </p>
        </div>
      );
    },
  },
  {
    Header: "No of Case Folders",

    Cell: ({ cell: { row } }: any) => {
      return (
        <p className="text-sm lg:ml-[20%]">
          {row.original?.case_folders_count}
        </p>
      );
    },
  },

  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      const dispatch = useAppDispatch();
      const user = useAppSelector(selectUser);

      return (
        <>
          <Link to={`/presiding-judge/judges/open?id=${row.original?.id}`}>
            <button
              onClick={() => dispatch(updateCase(row.original))}
              className="px-1 py-1 bg-brand_indigo text-[#fff] text-xs  w-20  rounded-md"
            >
              Open
            </button>
          </Link>
        </>
      );
    },
  },
];

export const SINGLE_CASE_COLUMNS = [
  {
    Header: "Suit no",
    accessor: "suit_number",
  },
  {
    Header: "Case Title",
    accessor: "title",
  },
  {
    Header: "Date created",
    accessor: "created_at",
    Cell: ({ cell: { row } }: any) => {
      return moment(row.values.created_at).format("MMM Do YYYY");
    },
  },
  {
    Header: "Status",
    Cell: ({ cell: { row } }: any) => {
      return (
        <button
          className={`px-2 py-1 text-gray-500 text-xs cursor-default ${
            row.original?.case_status?.status === "PENDING"
              ? "bg-yellow-200 h-[4vh]  rounded-md"
              : row.original?.case_status?.status === "MOTION"
              ? "bg-yellow-200 h-[4vh]  rounded-md"
              : row.original?.case_status?.status === "IN COURT"
              ? "bg-[#F2994A] h-[4vh]  rounded-md"
              : row.original?.case_status?.status === "NOT SERVED"
              ? "bg-red-100 h-[4vh]  rounded-md"
              : row.original?.case_status?.status === "REJECTED"
              ? "bg-red-100 h-[4vh]  rounded-md"
              : row.original?.case_status?.status === "FRESH CASE"
              ? "bg-green-200 h-[4vh]  rounded-md"
              : "bg-green-200 h-[4vh]  rounded-md"
          }`}
        >
          {row.original?.case_status?.status}
        </button>
      );
    },
  },
  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      const dispatch = useAppDispatch();
      return (
        <>
          <button
            onClick={() => {
              dispatch(setToggle_2(true));
            }}
            className={`px-2 py-1 text-white text-xs hover:opacity-70  rounded-md bg-brand_indigo w-16`}
          >
            View
          </button>
        </>
      );
    },
  },
];

export const PARTY_COLUMNS = [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "First name.",
    accessor: "first_name",
  },
  {
    Header: "Last name",
    accessor: "last_name",
  },

  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      const dispatch = useAppDispatch();
      const handleDelete = (item: any) => {
        // console.log(item);

        dispatch(removeValue(item));
      };

      return (
        <>
          <button
            onClick={() => handleDelete(row.original)}
            type="button"
            className={`px-1 py-1 hover:opacity-70 text-white text-xs  rounded-md bg-red-600`}
          >
            Delete
          </button>
        </>
      );
    },
  },
];

export const HEARING_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },

  {
    Header: "Phone Number",
    accessor: "phone_number",
  },
  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      const dispatch = useAppDispatch();
      const handleDelete = (item: any) => {
        // console.log(item);

        dispatch(removeValue(item));
      };

      return (
        <>
          <button
            onClick={() => handleDelete(row.original)}
            type="button"
            className={`px-1 py-1 hover:opacity-70 text-white text-xs  rounded-md bg-red-600`}
          >
            Delete
          </button>
        </>
      );
    },
  },
];

export const PARTY_COLUMNS_view = [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "First name.",
    accessor: "first_name",
  },
  {
    Header: "Last name",
    accessor: "last_name",
  },

  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      const dispatch = useAppDispatch();

      return (
        <>
          <button
            onClick={() => {
              dispatch(setSingleParty(row?.original));
              dispatch(setToggle(true));
            }}
            className={`px-2 py-1 hover:opacity-70 text-white text-xs  rounded-md bg-brand_indigo w-16`}
          >
            View
          </button>
        </>
      );
    },
  },
];

export const PARTY_COLUMNS_Edit = [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "First name.",
    accessor: "first_name",
  },
  {
    Header: "Last name",
    accessor: "last_name",
  },

  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      const dispatch = useAppDispatch();
      const navigate = useNavigate();
      const handleDelete = () => {
        Api.delete(`lawyer/parties/${row?.original?.id}`, getJSONheader())
          .then((res) => {
            toast.success(res.data.message, {
              duration: 3000,
            });
            dispatch(setReload(20));
            navigate("/lawyer/my-files");
          })
          .catch((error: any) => {
            toast.error(error?.res?.data?.message, {
              duration: 3000,
            });
          });
      };

      return (
        <div className="w-full flex justify-around">
          <a
            type="button"
            onClick={() => {
              dispatch(setSingleParty(row?.original));
              dispatch(setToggle(true));
            }}
            className={`  text-brand_indigo text-xs font-bold flex cursor-pointer`}
          >
            Edit
          </a>
          <div className="h-4 border-l-2 border-black w-[1px]"></div>
          <a
            type="button"
            onClick={() => {
              handleDelete();
            }}
            className={` text-red-600 text-xs  font-bold cursor-pointer`}
          >
            Delete
          </a>
        </div>
      );
    },
  },
];

export const PARTY_COLUMNS_EXISTING = [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "First name.",
    accessor: "first_name",
  },
  {
    Header: "Last name",
    accessor: "last_name",
  },

  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Action",
    // accessor: '',
    Cell: ({ cell: { row } }: any) => {
      const dispatch = useAppDispatch();
      const data = useAppSelector(getSelectedCase);

      const handleDelete = (e: any) => {
        e.preventDefault();
        let x = 1;
        Api.delete(`lawyer/parties/${row?.original?.id}`, getJSONheader())
          .then((res) => {
            // console.log(res);
            toast.success(res.data.message, {
              duration: 3000,
            });
            Api.get(
              `/lawyer/case-folders?search=${data[0]?.suit_number}`,
              getJSONheader()
            ).then((res) => {
              // setData(res.data.data);
              dispatch(updateCase(res.data.data));
            });
          })
          .catch((error: any) => {
            toast.error(error?.res?.data?.message, {
              duration: 3000,
            });
          });
      };

      return (
        <>
          <button
            onClick={handleDelete}
            className={`px-2 py-1 text-white text-xs  rounded-md bg-red-600`}
          >
            Delete
          </button>
        </>
      );
    },
  },
];
export const DOC_COLUMNS = [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "File name",
    accessor: "name",
  },
  {
    Header: "File Format",
    accessor: "type",
  },
];

export const PR_JUDGE_COLUMNS = [
  {
    Header: "S/No.",

    Cell: ({ cell: { row } }: any) => {
      return <span className="text-xs">{Number(row.id) + 1}</span>;
    },
  },
  {
    Header: "Suit no",
    accessor: "suit_number",
  },
  {
    Header: "Case Title",
    accessor: "title",
  },
  {
    Header: "Date created",
    accessor: "created_at",
    Cell: ({ cell: { row } }: any) => {
      return moment(row.values.created_at).format("MMM Do YYYY");
    },
  },

  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      // console.log(row, 'ROW');
      const judge = useAppSelector(getSelectedCase);
      const dispatch = useDispatch();

      const handleRecall = (e: any) => {
        e.preventDefault();
        let x = 1;
        Api.post(
          `/presiding/case-folders/recall`,
          { case_status_id: row?.original?.case_status?.id },
          getJSONheader()
        )
          .then((res) => {
            // console.log(res);
            toast.success(res.data.message, {
              duration: 3000,
            });
            Api.get(
              `/presiding/judges/${judge.id}/case-folders`,
              getJSONheader()
            ).then((res) => {
              updateCase(row.original);
              window.location.reload();
            });
          })
          .catch((error: any) => {
            toast.error(error?.res?.data?.message, {
              duration: 3000,
            });
          });
      };

      return (
        <>
          <button
            onClick={(e) => handleRecall(e)}
            className="px-1 py-1 bg-red-600 text-[#fff] text-xs  w-20  rounded-md"
          >
            Recall
          </button>
        </>
      );
    },
  },
];

export const MEETING_COLUMNS = [
  {
    Header: "S/No.",

    Cell: ({ cell: { row } }: any) => {
      return <span className="text-xs">{Number(row.id) + 1}</span>;
    },
  },

  {
    Header: "Case Title",
    // accessor: 'title',
    Cell: ({ cell: { row } }: any) => {
      return (
        <span className="text-xs">{row.original?.case_folder?.title}</span>
      );
    },
  },
  {
    Header: "Suit number",

    Cell: ({ cell: { row } }: any) => {
      return (
        <span className="text-xs">
          {row.original?.case_folder?.suit_number}
        </span>
      );
    },
  },
  {
    Header: "Hearing Description",
    accessor: "description",
  },
  {
    Header: "Scheduled date",
    accessor: "created_at",
    Cell: ({ cell: { row } }: any) => {
      return moment(row.original.scheduled_at).format("MMM Do YYYY");
    },
  },
  {
    Header: "Hearing type",
    accessor: "type",
  },
  {
    Header: "Meeting Link",
    accessor: "join_url",
  },

  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      return (
        <>
          {row.original?.type === "physical" ? (
            <></>
          ) : (
            <a
              target="_blank"
              href={row.original.join_url}
              className="px-4 py-1 bg-brand_indigo text-[#fff] text-xs  w-20  rounded-md"
              rel="noreferrer"
            >
              Join
            </a>
          )}
        </>
      );
    },
  },
];

export const CASE_ASSIGN_COLUMNS = [
  {
    Header: "S/No.",

    Cell: ({ cell: { row } }: any) => {
      return <span className="text-xs">{Number(row.id) + 1}</span>;
    },
  },
  {
    Header: "Case Number",
    accessor: "case_number",
  },
  {
    Header: "Suit Number",
    accessor: "suit_number",
  },
  {
    Header: "Case Title",
    accessor: "title",
  },
  {
    Header: "Date created",
    accessor: "created_at",
    Cell: ({ cell: { row } }: any) => {
      return moment(row.values.created_at).format("MMM Do YYYY");
    },
  },
  {
    Header: "Amount Paid (₦)",
    accessor: "amount_paid",
  },

  {
    Header: "Status",
    Cell: ({ cell: { row } }: any) => {
      return (
        <>
          <button
            className={`px-2 py-1 text-gray-500 text-xs cursor-default  rounded-md capitalize ${
              row.original?.case_status?.status === "PENDING"
                ? "bg-yellow-200"
                : row.original?.case_status?.status === "IN COURT"
                ? "bg-[#F2994A]"
                : row.original?.case_status?.status === "RECALLED"
                ? "bg-red-200"
                : "bg-green-200"
            }`}
          >
            {row.original?.case_status?.status}
          </button>
        </>
      );
    },
  },

  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      const dispatch = useAppDispatch();
      const user = useAppSelector(selectUser);

      return (
        <>
          {row.original?.case_status?.status === "RECALLED" ? (
            // <Link to={'#'}>
            <button
              onClick={() => dispatch(updateCase(row.original))}
              className="px-1  cursor-default py-1 bg-yellow-500 text-[#fff] text-xs  w-20  rounded-md"
            >
              In Progress
            </button>
          ) : (
            // </Link>
            <Link
              to={
                user.role === "REGISTRAR 2"
                  ? `/registrar-2/process?id=${row.original?.id}`
                  : user.role === "PRESIDING JUDGE"
                  ? `/presiding-judge/process?id=${row.original?.id}`
                  : `/registrar-1/process?id=${row.original?.id}`
              }
            >
              <button
                onClick={() => dispatch(updateCase(row.original))}
                className="px-1 py-1 bg-brand_indigo text-[#fff] text-xs  w-20  rounded-md"
              >
                Process
              </button>
            </Link>
          )}
        </>
      );
    },
  },
];

export const CASE_FOLDER_COLUMNS = [
  {
    Header: "S/No.",

    Cell: ({ cell: { row } }: any) => {
      return <span className="text-xs">{Number(row.id) + 1}</span>;
    },
  },
  {
    Header: "Case number",
    accessor: "case_number",
  },
  {
    Header: "Suit no",
    accessor: "suit_number",
  },
  {
    Header: "Case Title",
    accessor: "title",
  },
  {
    Header: "Date created",
    accessor: "created_at",
    Cell: ({ cell: { row } }: any) => {
      return moment(row.values.created_at).format("MMM Do YYYY");
    },
  },

  {
    Header: "Status",
    Cell: ({ cell: { row } }: any) => {
      return (
        <button
          className={`px-2 py-1 text-gray-500 text-xs cursor-default  rounded-md capitalize ${
            row.original?.case_status?.status === "PENDING"
              ? "bg-yellow-200"
              : row.original?.case_status?.status === "IN COURT"
              ? "bg-[#F2994A]"
              : row.original?.case_status?.status === "RECALLED"
              ? "bg-red-200"
              : "bg-green-200"
          }`}
        >
          {row.original?.case_status?.status}
        </button>
      );
    },
  },

  {
    Header: "Action",
    Cell: ({ cell: { row } }: any) => {
      // console.log(row, 'ROW');
      const dispatch = useAppDispatch();
      const navigate = useNavigate();
      const dropdownRef = useRef<HTMLDivElement>(null);
      const [dropdown, setDropdown] = useState<string | null>(null);
      const toggleDropDown = (id: string) => {
        setDropdown((prev) => (prev === id ? null : id));
      };

      useEffect(() => {
        const handleClickOutSide = (event: MouseEvent) => {
          if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
          ) {
            setDropdown(null);
          }
        };
        document.addEventListener("mousedown", handleClickOutSide);
        return () =>
          document.removeEventListener("mousedown", handleClickOutSide);
      }, []);

      return (
        <div ref={dropdownRef} className="relative">
          {/* {row.original?.case_status?.status === "ACCEPTED" ? (
            <Link to={`/lawyer/proceed?id=${row.original?.id}`}>
              <button
                onClick={() => dispatch(updateCase(row.original))}
                className="px-1 py-1 bg-brand_indigo text-[#fff] text-xs  w-20  rounded-md"
              >
                Proceed
              </button>
            </Link>
          ) : row.original?.case_status?.status === "REJECTED" ? (
            <Link to={`/lawyer/edit-case?id=${row.original?.id}`}>
              <button
                onClick={() => dispatch(updateCase(row.original))}
                className="px-1 py-1 bg-yellow-300 text-[#fff] text-xs  w-20  rounded-md"
              >
                Edit
              </button>
            </Link>
          ) : (
            <Link to={`/lawyer/view-case?id=${row.original?.id}`}>
              <button
                onClick={() => dispatch(updateCase(row.original))}
                className="px-1 py-1 bg-brand_blue text-[#fff] text-xs  w-20  rounded-md"
              >
                View
              </button>
            </Link>
          )} */}
          <FaEllipsisV
            size={20}
            color="#101828"
            className="hover:bg-green-100 p-1 cursor-pointer rounded-md"
            onClick={() => toggleDropDown(row?.original?.id)}
          />
          <Transition
            as="div"
            show={dropdown === row?.original?.id}
            className="w-[170px] h-[101px] flex flex-col gap-y-2 items-start rounded-xl shadow-lg absolute right-10 z-30 bg-white mt-6 text-sm font-medium py-5"
          >
            <span
              className="hover:bg-[#BFD0F2] w-full py-1 pl-3 cursor-pointer"
              onClick={() => {
                navigate(`/lawyer/view-folder?=${row?.original?.id}`);
                dispatch(updateCase(row?.original));
              }}
            >
              Open folder
            </span>
            <span className="hover:bg-[#BFD0F2] w-full py-1 pl-3 cursor-pointer">
              View case details
            </span>
          </Transition>
        </div>
      );
    },
  },
];
