/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { JUSTICE, LOGO, PDF, UPLOAD } from "../../assets";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../components/form/Input";
import Button from "../../components/button";
import { Fade } from "react-reveal";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Zoom } from "react-reveal";
import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import { useAppDispatch } from "../../hooks";
import { setUser } from "../../states/slices/authReducer";
import Api from "../../config";
import { signupSchema } from "../../schemas/signup";
import { BsChevronLeft } from "react-icons/bs";
import { useDropzone } from "react-dropzone";

function Signup() {
  const [stepVlaue, setStepValue] = useState(0);
  const [required, setRequired] = useState("");
  const [file, setFile] = useState<any>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailChange = (event: any) => {
    const newValue = event.target.value;
    setEmail(newValue);

    // Custom email validation logic
    if (!newValue) {
      // Handle empty value
      setIsValidEmail(true); // Allow empty value
    } else if (newValue.endsWith("@nigerianbar.ng")) {
      setIsValidEmail(true); // Valid email format
    } else {
      setIsValidEmail(false); // Invalid email format
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 50000000,
    accept: {
      "application/pdf": [".pdf"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
    onDrop: (acceptedFiles) => {
      setFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const productFiles = file.map((item: any, index: number) => (
    <div className="w-[80%]">
      <div className="flex justify-start items-center">
        <img src={file[0]?.preview} className="h-40 w-80 " alt="" />
      </div>
      <div className="flex justify-between items-center">
        <p
          className="text-center text-green-600 text-sm ml-4 font-semibold"
          key={index}
        >
          {item.name}
        </p>
        <div className="flex justify-end">
          <button
            onClick={() => removeAll()}
            type="button"
            className={`px-1 py-1 hover:opacity-70 text-white text-xs  rounded-md bg-red-600`}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  ));

  const handleStepError = () => {
    setStepValue(stepVlaue + 1);
    setRequired("");
  };
  const removeAll = () => {
    setFile([]);
  };
  const steps = ["Personal details", "Contact details", "Security protocol"];

  return (
    <div className="bg-white font-family-karla h-screen">
      <div className="w-full flex flex-wrap">
        <div className="w-full md:w-3/5 flex flex-col relative">
          <div className="flex  absolute justify-center md:justify-start pt-12 md:pl-12 md:-mb-24">
            <Link to="/">
              <img
                src={LOGO}
                className="h-full w-full rounded-full cursor-pointer"
                alt="...logo"
              />
            </Link>
          </div>
          <div className="absolute flex justify-between pt-12 md:pl-12 md:-mb-24 right-0">
            {stepVlaue > 0 && (
              <div className="h-12 w-20 mr-8">
                <a
                  onClick={() => {
                    setStepValue(stepVlaue - 1);

                    setRequired("");
                  }}
                  className="p-4 cursor-pointer flex justify-between items-center text-blue-700 hover:text-blue-900"
                >
                  <BsChevronLeft size={"1.75em"} /> <span>Back</span>
                </a>
              </div>
            )}
          </div>
          <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
            <p className="text-center text-xl font-bold">Create your Account</p>
            {/* {console.log(values)} */}
            <Fade left>
              <Formik
                initialValues={{
                  first_name: "",
                  last_name: "",
                  role: "",
                  gender: "",
                  password: "",
                  confirm_password: "",
                  email: "",
                  phone_number: "",
                  whatsapp: "",
                  age: "",
                  email_ng: "",
                  enrollment: "",
                }}
                validationSchema={signupSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setIsLoading(true);
                  const formData = new FormData();

                  formData.append("first_name", values.first_name);
                  formData.append("last_name", values.last_name);
                  formData.append("role", values.role);
                  formData.append("gender", values.gender);
                  formData.append("password", values.password);
                  formData.append("email", email);
                  formData.append("phone_number", values.phone_number);
                  formData.append("whatsapp_number", values.whatsapp);
                  if (values.enrollment) {
                    formData.append("enrollment_number", values.enrollment);
                  }
                  if (values?.role === "NON LAWYER") {
                    formData.append("age", values.age);
                  }
                  if (!isValidEmail && file.length > 0) {
                    formData.append("call_to_bar_certificate", file[0]);
                  }

                  Api.post("/auth/register", formData)
                    .then(({ data }) => {
                      // Redirect or perform any other action upon successful login
                      if (
                        data.message ===
                        "Your account is awaiting approval, we will notify you once this process is completed"
                      ) {
                        toast.success(data.message, {
                          duration: 3000, // Duration for which the toast is displayed (in milliseconds)
                        });
                        setIsLoading(false);
                        resetForm();
                        navigate("/non-verify");
                      } else {
                        toast.success(data.message, {
                          duration: 3000, // Duration for which the toast is displayed (in milliseconds)
                        });
                        setIsLoading(false);
                        resetForm();
                        dispatch(setUser(data?.data));
                        // console.log('data', data);

                        navigate("/email-Verification");
                        // email-Verification
                      }
                    })
                    .catch((error: any) => {
                      console.log(error.response.data.message);
                      toast.error(error?.response?.data?.message, {
                        duration: 3000,
                      });
                      setIsLoading(false);
                    });
                }}
              >
                {({
                  handleSubmit,
                  isSubmitting,
                  values,
                  handleChange,
                  /* and other goodies */
                }) => (
                  <Form className="flex flex-col pt-3 md:pt-8">
                    <Box sx={{ width: "100%" }}>
                      <Stepper activeStep={stepVlaue} alternativeLabel>
                        {steps.map((label) => (
                          <Step
                            key={label}
                            sx={{
                              "& .MuiStepLabel-root .Mui-completed": {
                                color: "#272556", // circle color (COMPLETED)
                              },
                              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                                {
                                  color: "#fff", // Just text label (COMPLETED)
                                },
                              "& .MuiStepLabel-root .Mui-active": {
                                color: "#0cabdc", // circle color (ACTIVE)
                              },
                              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                                {
                                  color: "#fff", // Just text label (ACTIVE)
                                },
                              "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text":
                                {
                                  color: "#fff", // circle's number (ACTIVE)
                                },
                            }}
                          >
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Box>
                    {stepVlaue === 0 && (
                      <Zoom>
                        <div className="flex justify-between items-center flex-wrap pt-0 lg:pt-6">
                          <div className="flex flex-col pt-4  w-full lg:w-[48%]">
                            <label htmlFor="email" className="text-sm">
                              First name{" "}
                              <small className="text-red-500">*</small>
                            </label>
                            <Input
                              type="text"
                              placeholder="Enter your first name"
                              name={"first_name"}
                            />
                          </div>
                          <div className="flex flex-col pt-4 w-full lg:w-[48%]">
                            <label htmlFor="lastname" className="text-sm">
                              Last name{" "}
                              <small className="text-red-500">*</small>
                            </label>
                            <Input
                              type="text"
                              placeholder="Enter your last name"
                              name={"last_name"}
                            />
                          </div>

                          <div className="flex flex-col pt-4  w-full lg:w-[48%] mb-2 lg:mb-6">
                            <label htmlFor="email" className="text-sm">
                              Gender <small className="text-red-500">*</small>
                            </label>
                            <select
                              name="gender"
                              value={values.gender}
                              onChange={handleChange}
                              className={` appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
                            >
                              <option value="">
                                <span className="text-gray-200">
                                  Select an option
                                </span>
                              </option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div>
                          <div className="flex flex-col pt-4  w-full lg:w-[48%] mb-2 lg:mb-6">
                            <label htmlFor="email" className="text-sm">
                              Role <small className="text-red-500">*</small>
                            </label>
                            <select
                              name={"role"}
                              value={values.role}
                              onChange={handleChange}
                              className={`appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
                            >
                              <option value="" className="text-gray-200">
                                Select an option
                              </option>
                              <option value="LAWYER">Lawyer</option>
                              <option value="NON LAWYER">Non-Lawyer</option>
                            </select>
                          </div>
                          {values?.role === "NON LAWYER" && (
                            <Zoom>
                              <div className="flex flex-col pt-4  w-full lg:w-[48%] mb-2 lg:mb-6">
                                <label htmlFor="email" className="text-sm">
                                  Age <small className="text-red-500">*</small>
                                </label>
                                <select
                                  name={"age"}
                                  value={values.age}
                                  onChange={handleChange}
                                  className={`appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
                                >
                                  <option value="" className="text-gray-200">
                                    Select an option
                                  </option>
                                  <option value="Less than 18 years">
                                    Less than 18 years
                                  </option>
                                  <option value="More than 18 years">
                                    More than 18 years
                                  </option>
                                </select>
                              </div>
                            </Zoom>
                          )}
                        </div>
                      </Zoom>
                    )}
                    {stepVlaue === 1 && (
                      <Zoom>
                        <div className="flex justify-between items-start flex-wrap pt-0 lg:pt-6">
                          <div className="flex flex-col pt-4  w-full lg:w-[48%]">
                            <label htmlFor="lastname" className="text-sm">
                              Phone number{" "}
                              <small className="text-red-500">*</small>
                            </label>
                            <Input
                              type="text"
                              placeholder="Enter your phone number"
                              name={"phone_number"}
                            />
                          </div>
                          <div className="flex flex-col pt-4  w-full lg:w-[48%]">
                            <label htmlFor="email" className="text-sm">
                              Whatsapp number{" "}
                              <small className="text-red-500">*</small>
                            </label>
                            <Input
                              type="text"
                              placeholder="Enter your first name"
                              name={"whatsapp"}
                            />
                          </div>

                          <div className="flex flex-col pt-4 w-full">
                            <label htmlFor="email" className="text-sm">
                              Email address{" "}
                              {values?.role === "LAWYER" && (
                                <small className="font-semibold">
                                  (preferrably your Nigeria Bar Association
                                  email address)
                                </small>
                              )}
                            </label>

                            <input
                              type="text"
                              value={email}
                              onChange={handleEmailChange}
                              className={`bg-[#13BAED0D] border-[#13baed4b] appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200 `}
                            />
                            {/* {!isValidEmail && <p className='text-red-500'>Invalid email format</p>} */}
                          </div>
                          <Zoom>
                            {!isValidEmail && values?.role === "LAWYER" && (
                              <div className="flex flex-col pt-4  w-full lg:w-[48%]">
                                <label htmlFor="lastname" className="text-sm">
                                  Call to bar Certificate{" "}
                                  <small className="text-red-500">*</small>
                                </label>

                                <div className="" {...getRootProps()}>
                                  <div className="w-full">
                                    {file.length > 0 ? (
                                      productFiles
                                    ) : (
                                      <label className="flex flex-col justify-center items-center min-h-[250px] w-full px-4 transition border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                                        <img
                                          src={UPLOAD}
                                          alt=""
                                          className="h-12"
                                        />
                                        <p className="flex flex-col items-center space-x-2">
                                          <span className="font-medium text-gray-600 mt-4 text-center text-sm">
                                            Click to upload or drag and drop{" "}
                                            <br />
                                            Maxiumum file size 10 MB
                                          </span>
                                          <a className="h-10 px-6 py-2 mt-4 lg:mt-0  ml-4 font-normal items-center text-black bg-BRAND-200 border border-BRAND-200 rounded-xl ">
                                            Browse file
                                          </a>
                                        </p>
                                        <span className="text-[#9CA3AF] text-xs mt-4 ">
                                          Supported PDF, PNG, JPEG and JPG files
                                          only
                                        </span>
                                        <input
                                          type="file"
                                          name="file_upload"
                                          className="hidden"
                                          {...getInputProps()}
                                        />
                                      </label>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Zoom>
                          {!isValidEmail && values?.role === "LAWYER" && (
                            <Zoom>
                              <div className="flex flex-col pt-4  w-full lg:w-[48%] mb-2 lg:mb-6">
                                <label htmlFor="email" className="text-sm">
                                  Enrollment Number
                                  <small className="text-red-500">*</small>
                                </label>
                                <Input
                                  type="text"
                                  placeholder="Enter your last name"
                                  name={"enrollment"}
                                />
                              </div>
                            </Zoom>
                          )}
                        </div>
                      </Zoom>
                    )}
                    {stepVlaue === 2 && (
                      <Zoom>
                        <div className="flex justify-between items-center flex-wrap pt-0 lg:pt-6">
                          <div className="flex flex-col pt-4 w-full">
                            <label htmlFor="email" className="text-sm">
                              Password <small className="text-red-500">*</small>{" "}
                            </label>
                            <Input
                              isPassword
                              placeholder="Enter your password"
                              name="password"
                            />
                          </div>
                          <div className="flex flex-col pt-4 w-full">
                            <label htmlFor="lastname" className="text-sm">
                              Confirm password{" "}
                              <small className="text-red-500">*</small>
                            </label>
                            <Input
                              isPassword
                              placeholder="Confirm your password"
                              name={"confirm_password"}
                            />
                          </div>
                        </div>
                      </Zoom>
                    )}
                    <small className="text-center my-6 text-red-500">
                      {required}
                    </small>
                    <Button
                      title={stepVlaue > 1 ? "Submit" : "Next"}
                      type={"button"}
                      isLoading={isLoading}
                      // disable={isLoading}
                      onClick={(e: any) =>
                        stepVlaue === 0 &&
                        values.first_name &&
                        values.last_name &&
                        values.role &&
                        values.gender
                          ? handleStepError()
                          : stepVlaue === 1 &&
                            values.phone_number &&
                            values.whatsapp &&
                            email &&
                            (!isValidEmail && values?.role === "NON LAWYER"
                              ? email
                              : !isValidEmail && values?.role === "LAWYER"
                              ? file?.length
                              : isValidEmail)
                          ? //   (!isValidEmail && values?.role === 'LAWYER' ? values.enrollment : null)
                            handleStepError()
                          : stepVlaue === 2 &&
                            values.password &&
                            values.first_name &&
                            values.last_name &&
                            values.role &&
                            values.gender &&
                            values.phone_number &&
                            values.whatsapp
                          ? handleSubmit(e)
                          : setRequired("Kindly fill all details to proceed")
                      }
                      bgColor={"bg-brand_indigo"}
                      textColor={"text-white"}
                      className={" my-2  lg:my-0 w-full py-4 rounded-2xl"}
                    />
                  </Form>
                )}
              </Formik>
            </Fade>
            <div className="text-center pt-12 pb-12">
              <p className="text-sm">
                Already have an account?{" "}
                <Link to="/login" className="text-brand_blue font-semibold">
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className="w-2/5 shadow-2xl">
          <img
            alt=""
            className="object-cover w-full h-screen hidden md:block"
            src={JUSTICE}
          />
        </div>
      </div>
    </div>
  );
}

export default Signup;
